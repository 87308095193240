import React, {useEffect} from "react";
import { NavigationMenu } from "../../components/NavigationMenu";
import "./style.css";

export const Prosprr = () => {
  useEffect(() => {
    document.body.style.transform = `scale(${window.innerWidth / 1920})`;
    document.body.style.transformOrigin = `top left`;
  });
  return (
    <div className="prosprr">
      <div className="div">
        <div className="overlap">
          <div className="rectangle" />
          <div className="the-challenge">
            <div className="frame">
              <div className="frame-2">
                <div className="text-wrapper">About:</div>
                <div className="frame-3">
                  <p className="p">
                    Prosprr is a new-age crypto platform that simplifies investing in crypto, enabling users to invest
                    in crypto coins and portfolios with automated SIPs, making investing ridiculously easy.
                  </p>
                  <p className="text-wrapper-2">
                    The founders reached out to us to help them assemble their website with two goals — explain the
                    product clearly and wow the users thoroughly specifically for the indian audience and retail
                    investors.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-4">
            <div className="heading-wrapper">
              <div className="heading">
                <div className="text-wrapper-3">Prosprr</div>
                <p className="website-digital">Website &amp; digital branding for a crypto investment platform.</p>
              </div>
            </div>
            <div className="group">
              <div className="frame-5">
                <div className="UIX">
                  <div className="frame-wrapper">
                    <div className="frame-6">
                      <div className="overlap-group-wrapper">
                        <div className="overlap-group">
                          <img className="line" alt="Line" src="/img/line-18-1.svg" />
                          <img className="img" alt="Line" src="/img/line-19.svg" />
                        </div>
                      </div>
                      <div className="text-wrapper-4">Fintech</div>
                    </div>
                  </div>
                </div>
                <div className="div-wrapper">
                  <div className="frame-wrapper">
                    <div className="frame-6">
                      <div className="overlap-group-wrapper">
                        <div className="overlap-group">
                          <img className="line" alt="Line" src="/img/line-18.svg" />
                          <img className="img" alt="Line" src="/img/line-19.svg" />
                        </div>
                      </div>
                      <div className="text-wrapper-4">Crypto</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-wrapper-5">Industry</div>
            </div>
          </div>
        </div>
        <div className="frame-7">
          <div className="text-wrapper-6">Homepage</div>
          <img className="home-page" alt="Home page" src="/img/home-page.png" />
        </div>
        <div className="element">
          <div className="overlap-2">
            <div className="text-wrapper-7">Visual Language</div>
            <div className="text-wrapper-7">Visual Language</div>
          </div>
          <div className="frame-8">
            <div className="overlap-group-2">
              <div className="overlap-3">
                <div className="text-wrapper-8">Aa</div>
                <div className="text-wrapper-9">Lexend Deca</div>
              </div>
              <div className="text-wrapper-10">AaBbCcDdEeFfGgHhIiJjKkLlMmNnOoPpQqRrSsTtUuVvWwXxYyZz</div>
            </div>
          </div>
          <div className="frame-9">
            <div className="frame-10">
              <div className="frame-11">
                <div className="frame-12">
                  <div className="text-wrapper-11">Celadon</div>
                  <div className="frame-13">
                    <div className="text-wrapper-12">#78CC7A</div>
                  </div>
                </div>
              </div>
              <div className="frame-14">
                <div className="frame-15">
                  <div className="text-wrapper-11">Pine Green</div>
                  <div className="frame-13">
                    <div className="text-wrapper-12"> #007662</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-16">
              <div className="frame-17">
                <div className="frame-15">
                  <div className="text-wrapper-13">Nayanza</div>
                  <div className="frame-13">
                    <div className="text-wrapper-14">#C9F9DF</div>
                  </div>
                </div>
              </div>
              <div className="frame-18">
                <div className="frame-15">
                  <div className="text-wrapper-13">Anti-White</div>
                  <div className="frame-13">
                    <div className="text-wrapper-14">#E9ECF0</div>
                  </div>
                </div>
              </div>
              <div className="frame-19">
                <div className="frame-15">
                  <div className="text-wrapper-13">Azure</div>
                  <div className="frame-13">
                    <div className="text-wrapper-14"> #DDE9E7</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="frame-20">
              <div className="frame-21">
                <div className="frame-15">
                  <div className="text-wrapper-15">Outer Space</div>
                  <div className="frame-13">
                    <div className="text-wrapper-16">#FFFFFF</div>
                  </div>
                </div>
              </div>
              <div className="frame-22">
                <div className="frame-15">
                  <div className="text-wrapper-15">Rich Black</div>
                  <div className="frame-13">
                    <div className="text-wrapper-16">#101728</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-23">
          <div className="text-wrapper-17">The Solution</div>
          <p className="text-wrapper-18">
            We spruced up the brand assets with a modernised approach and introduced a simplified communication strategy
            for relevancy and raising awareness about the ease of investing in crypto, just like other asset classes,
            specifically for the Indian audience. We also put together a fresh visual scheme that perfectly with the
            logo to look forward to new futures and redesign the pages following this style.
          </p>
        </div>
        <div className="mask-group-wrapper">
          <img className="mask-group" alt="Mask group" src="/img/mask-group.png" />
        </div>
        <img className="why-crypto-SIP" alt="Why crypto SIP" src="/img/why-crypto-sip.png" />
        <img className="why-prosppr" alt="Why prosppr" src="/img/why-prosppr.png" />
        <div className="learning-blog">
          <div className="footers">
            <div className="frame-24">
              <div className="frame-25">
                <div className="frame-26">
                  <img className="img-2" alt="Prosppr logo" src="/img/prosppr-logo-4.png" />
                  <div className="frame-27">
                    <div className="text-wrapper-19">Get the app now!</div>
                    <div className="frame-28">
                      <img className="image" alt="Image" src="/img/image-10-4.png" />
                      <img className="image" alt="Image" src="/img/image-11-4.png" />
                    </div>
                  </div>
                </div>
                <div className="frame-29">
                  <div className="frame-30">
                    <div className="company-links">
                      <div className="text-wrapper-20">About</div>
                    </div>
                    <div className="company-links">
                      <div className="text-wrapper-20">Why Invest in Crypto</div>
                    </div>
                    <div className="company-links">
                      <div className="text-wrapper-20">Why Crypto SIP</div>
                    </div>
                    <div className="div-2">
                      <div className="text-wrapper-20">Resources</div>
                    </div>
                  </div>
                  <img className="img-2" alt="Frame" src="/img/frame-83.svg" />
                </div>
              </div>
              <div className="frame-31">
                <img className="divider" alt="Divider" src="/img/divider-8.svg" />
                <div className="frame-25">
                  <div className="text-wrapper-21">Copyright 2022 99xTechnologies LTD</div>
                  <div className="frame-32">
                    <div className="text-wrapper-22">Privacy Policy</div>
                    <div className="ellipse" />
                    <div className="text-wrapper-22">Terms of service</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="overlap-4">
            <div className="header">
              <img className="frame-33" alt="Frame" src="/img/frame-1.svg" />
              <div className="frame-34">
                <div className="div-3">
                  <div className="text-wrapper-23">About us</div>
                  <div className="text-wrapper-23">Market</div>
                  <p className="text-wrapper-23">Why to invest on Crypto</p>
                  <div className="text-wrapper-23">Why Crypto SIP</div>
                </div>
                <div className="buttons">
                  <div className="text-wrapper-24">Download Prosprr</div>
                </div>
              </div>
            </div>
            <div className="header-2">
              <div className="overlap-group-3">
                <div className="ellipse-2" />
                <div className="ellipse-3" />
                <div className="ellipse-4" />
                <div className="ellipse-5" />
                <div className="frame-35">
                  <p className="text-wrapper-25">Learn to Invest With Us</p>
                  <p className="text-wrapper-26">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin purus massa, scelerisque id ipsum
                    nec, blandit hendrerit tortor.
                  </p>
                </div>
                <div className="popular">
                  <div className="frame-36">
                    <div className="text-wrapper-27">Popular</div>
                  </div>
                  <div className="frame-37">
                    <div className="frame-38">
                      <div className="text-wrapper-28">LOROM IPSUM</div>
                      <p className="text-wrapper-29">Lorem ipsum dolor sit amet, consectetur</p>
                    </div>
                    <div className="frame-38">
                      <div className="text-wrapper-30">LOROM IPSUM</div>
                      <p className="text-wrapper-29">Lorem ipsum dolor sit amet, consectetur</p>
                    </div>
                    <div className="frame-38">
                      <div className="text-wrapper-31">LOROM IPSUM</div>
                      <p className="text-wrapper-29">Lorem ipsum dolor sit amet, consectetur</p>
                    </div>
                    <div className="frame-38">
                      <div className="text-wrapper-30">LOROM IPSUM</div>
                      <p className="text-wrapper-29">Lorem ipsum dolor sit amet, consectetur</p>
                    </div>
                  </div>
                </div>
                <div className="how-compounding">
                  <div className="frame-39">
                    <img className="image-2" alt="Image" src="/img/image-16.png" />
                    <div className="frame-40">
                      <div className="frame-41">
                        <div className="text-wrapper-32">LOROM IPSUM</div>
                        <div className="frame-42">
                          <p className="text-wrapper-33">How Compounding helps to gain returns in SIP</p>
                          <div className="frame-43">
                            <p className="text-wrapper-34">
                              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin purus massa, scelerisque id
                              ipsum nec, blandit hendrerit tortor.
                            </p>
                            <div className="text-wrapper-35">Read More</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-44">
                        <div className="text-wrapper-36">AUTHORS NAME</div>
                        <div className="ellipse-6" />
                        <div className="text-wrapper-37">November 18, 2022</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="header-3">
                  <img className="frame-33" alt="Frame" src="/img/frame.png" />
                  <div className="frame-34">
                    <div className="div-3">
                      <div className="text-wrapper-23">About us</div>
                      <div className="text-wrapper-23">Market</div>
                      <p className="text-wrapper-23">Why to invest on Crypto</p>
                      <div className="text-wrapper-23">Why Crypto SIP</div>
                    </div>
                    <img className="downlaod-prosppr" alt="Downlaod prosppr" src="/img/downlaod-prosppr.png" />
                  </div>
                </div>
                <div className="frame-45">
                  <div className="frame-46">
                    <div className="frame-47">
                      <div className="text-wrapper-27">Latest blogs</div>
                    </div>
                    <div className="div-3">
                      <div className="frame-48">
                        <div className="text-wrapper-38">All</div>
                      </div>
                      <div className="frame-49">
                        <div className="text-wrapper-39">Cryptocurrency</div>
                      </div>
                      <div className="frame-49">
                        <div className="text-wrapper-39">Investment</div>
                      </div>
                      <div className="frame-49">
                        <div className="text-wrapper-39">Trading</div>
                      </div>
                      <div className="frame-49">
                        <div className="text-wrapper-39">Blockchain</div>
                      </div>
                      <div className="frame-49">
                        <div className="text-wrapper-39">Security</div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-50">
                    <div className="frame-51">
                      <div className="frame-52" />
                      <div className="frame-53">
                        <div className="frame-54">
                          <div className="text-wrapper-40">LOROM IPSUM</div>
                          <div className="frame-55">
                            <p className="text-wrapper-41">Amet laoreet mi ultricies. Sed nisl mauris, congue</p>
                            <div className="frame-54">
                              <p className="text-wrapper-42">
                                Suspendisse purus lorem, viverra lacinia magna sed, luctus tincidunt velit. Nam quam
                                tortor, ultrices eu sem quis, dapibus scelerisque nunc. Quisque ut neque
                              </p>
                              <img className="read-more" alt="Read more" src="/img/read-more-14.png" />
                            </div>
                          </div>
                        </div>
                        <div className="frame-56">
                          <div className="text-wrapper-43">AUTHORS NAME</div>
                          <div className="ellipse-7" />
                          <div className="text-wrapper-44">November 18, 2022</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-51">
                      <div className="frame-57" />
                      <div className="frame-53">
                        <div className="frame-54">
                          <div className="text-wrapper-40">LOROM IPSUM</div>
                          <div className="frame-55">
                            <p className="text-wrapper-41">Amet laoreet mi ultricies. Sed nisl mauris, congue</p>
                            <div className="frame-54">
                              <p className="text-wrapper-42">
                                Suspendisse purus lorem, viverra lacinia magna sed, luctus tincidunt velit. Nam quam
                                tortor, ultrices eu sem quis, dapibus scelerisque nunc. Quisque ut neque
                              </p>
                              <img className="read-more" alt="Read more" src="/img/read-more-13.png" />
                            </div>
                          </div>
                        </div>
                        <div className="frame-56">
                          <div className="text-wrapper-43">AUTHORS NAME</div>
                          <div className="ellipse-7" />
                          <div className="text-wrapper-44">November 18, 2022</div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-51">
                      <div className="frame-58" />
                      <div className="frame-53">
                        <div className="frame-54">
                          <div className="text-wrapper-40">LOROM IPSUM</div>
                          <div className="frame-55">
                            <p className="text-wrapper-41">Amet laoreet mi ultricies. Sed nisl mauris, congue</p>
                            <div className="frame-54">
                              <p className="text-wrapper-42">
                                Suspendisse purus lorem, viverra lacinia magna sed, luctus tincidunt velit. Nam quam
                                tortor, ultrices eu sem quis, dapibus scelerisque nunc. Quisque ut neque
                              </p>
                              <img className="read-more" alt="Read more" src="/img/read-more-12.png" />
                            </div>
                          </div>
                        </div>
                        <div className="frame-56">
                          <div className="text-wrapper-43">AUTHORS NAME</div>
                          <div className="ellipse-7" />
                          <div className="text-wrapper-44">November 18, 2022</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="frame-59">
            <div className="frame-60">
              <div className="text-wrapper-45">Cryptocurrency</div>
              <img className="rectangle-2" alt="Rectangle" src="/img/rectangle-85-8.png" />
            </div>
            <div className="div-3">
              <div className="frame-61">
                <div className="frame-62" />
                <div className="frame-63">
                  <p className="text-wrapper-41">
                    Amet laoreet mi ultricies. Sed nisl mauris, congue Integer porttitor magna
                  </p>
                  <div className="frame-44">
                    <div className="text-wrapper-43">AUTHORS NAME</div>
                    <div className="ellipse-7" />
                    <div className="text-wrapper-44">November 18, 2022</div>
                  </div>
                </div>
              </div>
              <div className="div-2">
                <div className="frame-43">
                  <div className="frame-64">
                    <div className="frame-65" />
                    <div className="frame-66">
                      <p className="text-wrapper-41">
                        Quisque ut neque ac risus suscipit facilisis. Integer porttitor magna eget rutrum fringilla.
                      </p>
                      <div className="frame-67">
                        <div className="text-wrapper-43">AUTHORS NAME</div>
                        <div className="ellipse-7" />
                        <div className="text-wrapper-44">November 18, 2022</div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-68">
                    <div className="frame-69" />
                    <div className="frame-66">
                      <p className="text-wrapper-41">
                        Quisque ut neque ac risus suscipit facilisis. Integer porttitor magna eget rutrum fringilla.
                      </p>
                      <div className="frame-67">
                        <div className="text-wrapper-43">AUTHORS NAME</div>
                        <div className="ellipse-7" />
                        <div className="text-wrapper-44">November 18, 2022</div>
                      </div>
                    </div>
                  </div>
                </div>
                <img className="more-blogs" alt="More blogs" src="/img/more-blogs-8.png" />
              </div>
            </div>
          </div>
          <div className="frame-70">
            <div className="frame-71">
              <div className="text-wrapper-46">Crypto Baskets</div>
              <img className="rectangle-3" alt="Rectangle" src="/img/rectangle-85-7.png" />
            </div>
            <div className="frame-72">
              <div className="frame-73">
                <div className="frame-74" />
                <div className="frame-63">
                  <p className="text-wrapper-41">
                    Amet laoreet mi ultricies. Sed nisl mauris, congue Integer porttitor magna
                  </p>
                  <div className="frame-44">
                    <div className="text-wrapper-43">AUTHORS NAME</div>
                    <div className="ellipse-7" />
                    <div className="text-wrapper-44">November 18, 2022</div>
                  </div>
                </div>
              </div>
              <div className="frame-75">
                <div className="frame-76">
                  <div className="frame-77">
                    <div className="frame-78" />
                    <div className="frame-66">
                      <p className="text-wrapper-41">
                        Quisque ut neque ac risus suscipit facilisis. Integer porttitor magna eget rutrum fringilla.
                      </p>
                      <div className="frame-79">
                        <div className="text-wrapper-43">AUTHORS NAME</div>
                        <div className="ellipse-7" />
                        <div className="text-wrapper-44">November 18, 2022</div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-80">
                    <div className="frame-81" />
                    <div className="frame-66">
                      <p className="text-wrapper-41">
                        Quisque ut neque ac risus suscipit facilisis. Integer porttitor magna eget rutrum fringilla.
                      </p>
                      <div className="frame-79">
                        <div className="text-wrapper-43">AUTHORS NAME</div>
                        <div className="ellipse-7" />
                        <div className="text-wrapper-44">November 18, 2022</div>
                      </div>
                    </div>
                  </div>
                </div>
                <img className="more-blogs" alt="More blogs" src="/img/more-blogs-7.png" />
              </div>
            </div>
          </div>
          <div className="frame-82">
            <div className="frame-71">
              <div className="text-wrapper-46">Blockchain</div>
              <img className="rectangle-3" alt="Rectangle" src="/img/rectangle-85-6.png" />
            </div>
            <div className="frame-72">
              <div className="frame-75">
                <div className="frame-76">
                  <div className="frame-77">
                    <div className="image-wrapper">
                      <img className="image-3" alt="Image" src="/img/image-41-3.png" />
                    </div>
                    <div className="frame-66">
                      <p className="text-wrapper-41">
                        Quisque ut neque ac risus suscipit facilisis. Integer porttitor magna
                      </p>
                      <div className="frame-44">
                        <div className="text-wrapper-43">AUTHORS NAME</div>
                        <div className="ellipse-7" />
                        <div className="text-wrapper-44">November 18, 2022</div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-80">
                    <div className="frame-83" />
                    <div className="frame-66">
                      <p className="text-wrapper-41">
                        Quisque ut neque ac risus suscipit facilisis. Integer porttitor magna
                      </p>
                      <div className="frame-44">
                        <div className="text-wrapper-43">AUTHORS NAME</div>
                        <div className="ellipse-7" />
                        <div className="text-wrapper-44">November 18, 2022</div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-80">
                    <div className="frame-84" />
                    <div className="frame-66">
                      <p className="text-wrapper-41">
                        Quisque ut neque ac risus suscipit facilisis. Integer porttitor magna
                      </p>
                      <div className="frame-44">
                        <div className="text-wrapper-43">AUTHORS NAME</div>
                        <div className="ellipse-7" />
                        <div className="text-wrapper-44">November 18, 2022</div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-80">
                    <div className="frame-85" />
                    <div className="frame-66">
                      <p className="text-wrapper-41">
                        Quisque ut neque ac risus suscipit facilisis. Integer porttitor magna
                      </p>
                      <div className="frame-44">
                        <div className="text-wrapper-43">AUTHORS NAME</div>
                        <div className="ellipse-7" />
                        <div className="text-wrapper-44">November 18, 2022</div>
                      </div>
                    </div>
                  </div>
                </div>
                <img className="more-blogs" alt="More blogs" src="/img/more-blogs-6.png" />
              </div>
            </div>
          </div>
          <div className="frame-86">
            <div className="frame-71">
              <div className="text-wrapper-46">Compounding</div>
              <img className="rectangle-3" alt="Rectangle" src="/img/rectangle-85-5.png" />
            </div>
            <div className="frame-72">
              <div className="frame-73">
                <div className="frame-87" />
                <div className="frame-63">
                  <p className="text-wrapper-41">
                    Amet laoreet mi ultricies. Sed nisl mauris, congue Integer porttitor magna
                  </p>
                  <div className="frame-44">
                    <div className="text-wrapper-43">AUTHORS NAME</div>
                    <div className="ellipse-7" />
                    <div className="text-wrapper-44">November 18, 2022</div>
                  </div>
                </div>
              </div>
              <div className="frame-75">
                <div className="frame-76">
                  <div className="frame-77">
                    <div className="frame-88" />
                    <div className="frame-66">
                      <p className="text-wrapper-41">
                        Quisque ut neque ac risus suscipit facilisis. Integer porttitor magna eget rutrum fringilla.
                      </p>
                      <div className="frame-79">
                        <div className="text-wrapper-43">AUTHORS NAME</div>
                        <div className="ellipse-7" />
                        <div className="text-wrapper-44">November 18, 2022</div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-80">
                    <div className="frame-89" />
                    <div className="frame-66">
                      <p className="text-wrapper-41">
                        Quisque ut neque ac risus suscipit facilisis. Integer porttitor magna eget rutrum fringilla.
                      </p>
                      <div className="frame-79">
                        <div className="text-wrapper-43">AUTHORS NAME</div>
                        <div className="ellipse-7" />
                        <div className="text-wrapper-44">November 18, 2022</div>
                      </div>
                    </div>
                  </div>
                </div>
                <img className="more-blogs" alt="More blogs" src="/img/more-blogs-5.png" />
              </div>
            </div>
          </div>
          <div className="frame-90">
            <div className="frame-71">
              <div className="text-wrapper-46">Essentials</div>
              <img className="rectangle-3" alt="Rectangle" src="/img/rectangle-85-4.png" />
            </div>
            <div className="frame-72">
              <div className="frame-73">
                <div className="frame-91" />
                <div className="frame-63">
                  <p className="text-wrapper-41">Amet laoreet mi ultricies. Sed nisl mauris,</p>
                  <div className="frame-44">
                    <div className="text-wrapper-43">AUTHORS NAME</div>
                    <div className="ellipse-7" />
                    <div className="text-wrapper-44">November 18, 2022</div>
                  </div>
                </div>
              </div>
              <div className="frame-75">
                <div className="frame-76">
                  <div className="frame-77">
                    <div className="frame-92" />
                    <div className="frame-66">
                      <p className="text-wrapper-41">Quisque ut neque ac risus suscipit facilisis</p>
                      <div className="frame-79">
                        <div className="text-wrapper-43">AUTHORS NAME</div>
                        <div className="ellipse-7" />
                        <div className="text-wrapper-47">November 18, 2022</div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-80">
                    <div className="frame-93" />
                    <div className="frame-66">
                      <p className="text-wrapper-41">Quisque ut neque ac risus suscipit facilisis.</p>
                      <div className="frame-79">
                        <div className="text-wrapper-43">AUTHORS NAME</div>
                        <div className="ellipse-7" />
                        <div className="text-wrapper-47">November 18, 2022</div>
                      </div>
                    </div>
                  </div>
                </div>
                <img className="more-blogs" alt="More blogs" src="/img/more-blogs-4.png" />
              </div>
            </div>
          </div>
        </div>
        <div className="overlap-wrapper">
          <div className="overlap-5">
            <div className="rectangle-4" />
            <div className="why-crypto-SIP-2">
              <div className="overlap-6">
                <div className="overlap-7">
                  <div className="component">
                    <div className="overlap-group-4">
                      <div className="ellipse-8" />
                      <div className="frame-94">
                        <div className="frame-95">
                          <div className="frame-96">
                            <div className="text-wrapper-48">Why Crypto SIP</div>
                            <div className="most-preferred">
                              Most Preferred <br />
                              Investment Avenue
                            </div>
                          </div>
                        </div>
                        <p className="text-wrapper-49">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin purus massa, scelerisque
                        </p>
                        <img className="img-2" alt="Get started" src="/img/get-started-12.png" />
                      </div>
                    </div>
                  </div>
                  <div className="frame-97">
                    <div className="overlap-8">
                      <div className="ellipse-9" />
                      <div className="frame-98">
                        <div className="headline">Jan 2021</div>
                        <div className="headline-2">₹3600</div>
                      </div>
                      <div className="ellipse-10" />
                      <div className="frame-99">
                        <div className="headline-3">Feb 2021</div>
                        <div className="headline-4">₹3500</div>
                      </div>
                      <div className="group-2">
                        <div className="overlap-group-5">
                          <div className="div-4" />
                          <img className="vector" alt="Vector" src="/img/vector-16-11.svg" />
                          <div className="ellipse-11" />
                          <div className="ellipse-12" />
                        </div>
                      </div>
                      <div className="group-3">
                        <div className="overlap-9">
                          <div className="ellipse-13" />
                          <img className="vector-2" alt="Vector" src="/img/vector-16-10.svg" />
                          <div className="ellipse-14" />
                          <div className="ellipse-15" />
                        </div>
                      </div>
                      <div className="group-4">
                        <div className="overlap-9">
                          <div className="ellipse-13" />
                          <img className="vector-2" alt="Vector" src="/img/vector-16-9.svg" />
                          <div className="ellipse-14" />
                          <div className="ellipse-15" />
                        </div>
                      </div>
                      <div className="group-5">
                        <div className="div-4">
                          <div className="ellipse-16" />
                        </div>
                        <img className="vector-3" alt="Vector" src="/img/vector-16-8.png" />
                        <div className="ellipse-11" />
                      </div>
                      <div className="group-6">
                        <div className="overlap-group-5">
                          <div className="div-4" />
                          <img className="vector" alt="Vector" src="/img/vector-16-7.svg" />
                          <div className="ellipse-11" />
                          <div className="ellipse-12" />
                        </div>
                      </div>
                      <div className="group-7">
                        <div className="overlap-10">
                          <div className="ellipse-17" />
                          <img className="vector-4" alt="Vector" src="/img/vector-16-6.svg" />
                          <div className="ellipse-11" />
                          <div className="ellipse-18" />
                        </div>
                      </div>
                      <img className="vector-5" alt="Vector" src="/img/vector-15-1.svg" />
                      <div className="frame-100">
                        <div className="headline-3">Mar 2021</div>
                        <div className="headline-4">₹4000</div>
                      </div>
                      <div className="frame-101">
                        <div className="headline-3">Apr 2021</div>
                        <div className="headline-4">₹4200</div>
                      </div>
                      <div className="frame-102">
                        <div className="headline-3">May 2021</div>
                        <div className="headline-4">₹3700</div>
                      </div>
                      <div className="frame-103">
                        <div className="headline-3">June 2021</div>
                        <div className="headline-4">₹3800</div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-104">
                  <div className="frame-105">
                    <NavigationMenu
                      className="leading-icon-l-use"
                      color="/img/color-5.svg"
                      colorClassName="navigation-menu-px"
                    />
                    <img className="vector-6" alt="Vector" src="/img/vector-6.svg" />
                  </div>
                  <img className="img-2" alt="Download prosppr" src="/img/download-prosppr-4.png" />
                </div>
              </div>
              <div className="frame-106">
                <div className="frame-107">
                  <div className="headline-5">Blog</div>
                  <div className="headline-6">Learn With Us</div>
                </div>
                <div className="frame-108">
                  <div className="how-compounding-2">
                    <div className="frame-109">
                      <img className="image-4" alt="Image" src="/img/image-15.png" />
                      <div className="frame-110">
                        <p className="text-wrapper-50">How Ruppee cost averaging works</p>
                        <div className="frame-111">
                          <p className="text-wrapper-51">Pellentesque habitant morbi tristique sen ...</p>
                          <img className="read-more-2" alt="Read more" src="/img/read-more-11.png" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="how-compounding-3">
                    <div className="frame-109">
                      <img className="image-4" alt="Image" src="/img/image-14.png" />
                      <div className="frame-110">
                        <p className="text-wrapper-50">How Ruppee cost averaging works</p>
                        <div className="frame-111">
                          <p className="text-wrapper-51">Pellentesque habitant morbi tristique sen ...</p>
                          <img className="img-2" alt="Read more" src="/img/read-more-10.png" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="how-compounding-4">
                    <div className="frame-109">
                      <img className="image-4" alt="Image" src="/img/image-13.png" />
                      <div className="frame-110">
                        <p className="text-wrapper-50">How Compounding helps to gain returns in SIP</p>
                        <div className="frame-111">
                          <p className="text-wrapper-51">Pellentesque habitant morbi tristique sen ...</p>
                          <img className="read-more-3" alt="Read more" src="/img/group-84.png" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="frame-112">
                <p className="text-wrapper-52">Start your Crypto Portfolio with us</p>
                <p className="text-wrapper-53">
                  Apply for early access to Prosprr, and be one of the first to join the movement.
                </p>
              </div>
              <div className="frame-113">
                <img className="leaf" alt="Leaf" src="/img/vector.png" />
                <img className="element-2" alt="Element" src="/img/vector.png" />
              </div>
              <div className="frame-114">
                <div className="frame-115">
                  <div className="frame-115">
                    <div className="frame-116">
                      <div className="prosppr-logo-wrapper">
                        <img className="prosppr-logo" alt="Prosppr logo" src="/img/group-84.png" />
                      </div>
                      <div className="frame-117">
                        <div className="text-wrapper-54">Get the app now!</div>
                        <div className="frame-118">
                          <img className="image-5" alt="Image" src="/img/vector.png" />
                          <img className="image-6" alt="Image" src="/img/vector.png" />
                        </div>
                      </div>
                    </div>
                    <div className="frame-119">
                      <div className="frame-115">
                        <div className="text-wrapper-55">̱H̱ome</div>
                        <p className="text-wrapper-56">Why to invest on Crypto</p>
                        <div className="text-wrapper-56">Monthly Subscriptions</div>
                      </div>
                      <div className="frame-115">
                        <div className="text-wrapper-55">Market</div>
                        <div className="text-wrapper-56">Why Crypto SIP</div>
                        <div className="text-wrapper-56">About us</div>
                      </div>
                    </div>
                  </div>
                  <img className="divider-2" alt="Divider" src="/img/vector.png" />
                  <div className="frame-120">
                    <div className="text-wrapper-57">Follow us</div>
                    <img className="social-buttons" alt="Social buttons" src="/img/vector.png" />
                  </div>
                  <img className="divider-3" alt="Divider" src="/img/vector.png" />
                </div>
                <div className="frame-121">
                  <div className="text-wrapper-58">Copyright 2022 99xTechnologies LTD</div>
                  <div className="frame-122">
                    <div className="text-wrapper-59">Privacy Policy</div>
                    <div className="text-wrapper-59">Terms of service</div>
                  </div>
                </div>
              </div>
              <div className="thumbs-up">
                <img className="image-7" alt="Image" src="/img/vector.png" />
              </div>
              <div className="frame-123">
                <div className="frame-124">
                  <div className="frame-125">
                    <div className="frame-126">
                      <p className="headline-7">Enjoy the Convenience of Investing</p>
                      <p className="text-wrapper-49">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin purus massa, scelerisque id ipsum
                        nec, blandit hendrerit tortor. Sed sed vestibulum arcu, Lorem ipsum dolor sit amet, consectetur
                        adipiscing
                      </p>
                      <img className="img-2" alt="Get started" src="/img/get-started-11.png" />
                    </div>
                    <div className="group-8">
                      <div className="overlap-11">
                        <div className="ellipse-19" />
                        <img className="leaf-2" alt="Leaf" src="/img/leaf-1-3.png" />
                        <img className="istock" alt="Istock" src="/img/istock-1359179449-1-1.png" />
                        <div className="emoji">
                          <img className="getpaidstock" alt="Getpaidstock" src="/img/getpaidstock-3-1.png" />
                        </div>
                        <div className="cryptobasket">
                          <div className="overlap-group-6">
                            <div className="rectangle-5" />
                            <div className="text-wrapper-60">Basket</div>
                            <div className="rectangle-6" />
                            <div className="text-wrapper-61">Coin</div>
                          </div>
                          <div className="text-wrapper-62">Enter Frequency</div>
                          <div className="text-wrapper-63">Duration</div>
                          <div className="frame-127">
                            <div className="text-wrapper-64">6 months</div>
                          </div>
                          <div className="frame-128">
                            <div className="text-wrapper-64">₹ 3,500</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-125">
                    <div className="frame-126">
                      <p className="headline-7">Rupee Cost Averaging across the Time</p>
                      <p className="text-wrapper-49">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin purus massa, scelerisque id ipsum
                        nec, blandit hendrerit tortor. Sed sed vestibulum arcu, Lorem ipsum dolor sit amet, consectetur
                        adipiscing
                      </p>
                      <img className="img-2" alt="Get started" src="/img/get-started-10.png" />
                    </div>
                    <div className="group-9">
                      <div className="overlap-12">
                        <div className="image-8" />
                        <div className="ETH">
                          <div className="overlap-group-7">
                            <img className="group-10" alt="Group" src="/img/group-49-1.png" />
                            <div className="frame-129">
                              <div className="frame-130">
                                <div className="ethereum-logo-wrapper">
                                  <img
                                    className="ethereum-logo"
                                    alt="Ethereum logo"
                                    src="/img/ethereum-logo-landscape-black-1-1.png"
                                  />
                                </div>
                                <div className="rise">
                                  <img className="group-11" alt="Group" src="/img/group-3.png" />
                                  <div className="text-wrapper-65">87%</div>
                                </div>
                              </div>
                              <div className="frame-43">
                                <div className="frame-43">
                                  <div className="text-wrapper-66">Etherium ETH</div>
                                  <div className="text-wrapper-67">₹ 3,500</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-125">
                    <div className="frame-126">
                      <div className="headline-7">Well-diversified portfolios</div>
                      <p className="text-wrapper-49">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin purus massa, scelerisque id ipsum
                        nec, blandit hendrerit tortor. Sed sed vestibulum arcu, Lorem ipsum dolor sit amet, consectetur
                        adipiscing
                      </p>
                      <img className="img-2" alt="Get started" src="/img/get-started-9.png" />
                    </div>
                    <div className="group-12">
                      <img className="check" alt="Check" src="/img/check-1-1.svg" />
                      <div className="frame-131">
                        <div className="frame-132">
                          <div className="frame-133">
                            <div className="group-13">
                              <div className="overlap-group-8">
                                <div className="vector-wrapper">
                                  <img className="vector-7" alt="Vector" src="/img/vector-9.svg" />
                                </div>
                                <div className="img-wrapper">
                                  <img className="vector-8" alt="Vector" src="/img/vector-8.svg" />
                                </div>
                                <div className="group-wrapper">
                                  <div className="PNG-wrapper">
                                    <img className="PNG" alt="Png" src="/img/png-vector69-3-1.png" />
                                  </div>
                                </div>
                                <div className="group-14">
                                  <img className="vector-9" alt="Vector" src="/img/vector-7.svg" />
                                </div>
                              </div>
                            </div>
                            <div className="frame-134">
                              <div className="CTA">
                                <div className="text-wrapper-68">Invest Now</div>
                              </div>
                              <img className="frame-135" alt="Frame" src="/img/frame-173-1.svg" />
                            </div>
                          </div>
                          <p className="text-wrapper-69">BNB + SOL + LUNA + DOT</p>
                        </div>
                        <div className="frame-136">
                          <div className="frame-137">
                            <div className="text-wrapper-70">Min Inv.</div>
                            <div className="text-wrapper-71">₹70.00</div>
                          </div>
                          <div className="frame-137">
                            <div className="text-wrapper-70">1Y Return</div>
                            <div className="rise-2">
                              <img className="group-15" alt="Group" src="/img/group-2.png" />
                              <div className="text-wrapper-72">87%</div>
                            </div>
                          </div>
                          <div className="frame-137">
                            <div className="text-wrapper-70">Total AUM</div>
                            <div className="text-wrapper-71">₹1.54 Cr</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-138">
                  <div className="overlap-13">
                    <div className="frame-139">
                      <div className="frame-140">
                        <div className="frame-141">
                          <div className="frame-96">
                            <div className="headline-8">Investments in Autopilot</div>
                            <p className="headline-9">
                              Everything you need to get started with Crypto Investing for the long haul and make your
                              assets take you towards prosperity is here.
                            </p>
                          </div>
                          <img className="img-2" alt="Get started" src="/img/get-started-8.png" />
                        </div>
                      </div>
                    </div>
                    <img className="investment-on" alt="Investment on" src="/img/investment-on-autopilot-bg-1.png" />
                  </div>
                </div>
              </div>
              <div className="frame-142">
                <div className="frame-143">
                  <img className="android-store" alt="Android store" src="/img/apple-store-2.png" />
                  <img className="apple-store" alt="Apple store" src="/img/apple-store-2.png" />
                </div>
                <div className="frame-144">
                  <img className="image-9" alt="Image" src="/img/image-29-2.png" />
                  <p className="text-wrapper-73">Scan the QR and download now</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="overlap-14">
          <img
            className="front-perspective"
            alt="Front perspective"
            src="/img/front-perspective-view-apple-studio-display-mockup-template-64d3.png"
          />
          <div className="frame-145">
            <div className="overlap-15">
              <img className="homepage" alt="Homepage" src="/img/homepage-1.png" />
              <img className="homepage-2" alt="Homepage" src="/img/homepage.png" />
              <div className="why-prosppr-2">
                <div className="overlap-16">
                  <div className="overlap-17">
                    <img className="hero-section-BG" alt="Hero section BG" src="/img/hero-section-bg-2.png" />
                    <div className="ellipse-20" />
                    <div className="frame-146">
                      <div className="text-wrapper-74">Why Prosppr</div>
                      <div className="text-wrapper-75">Invest while you</div>
                    </div>
                    <div className="input-field">
                      <img
                        className="enter-mobile-number"
                        alt="Enter mobile number"
                        src="/img/enter-mobile-number.png"
                      />
                      <img className="join-now" alt="Join now" src="/img/join-now.png" />
                    </div>
                    <img className="comma" alt="Comma" src="/img/comma.png" />
                    <div className="frame-147">
                      <div className="frame-148">
                        <NavigationMenu
                          className="navigation-menu-24px"
                          color="/img/color-4.svg"
                          colorClassName="navigation-menu-instance"
                        />
                        <img className="vector-10" alt="Vector" src="/img/vector-5.svg" />
                      </div>
                      <img className="download-prosppr" alt="Download prosppr" src="/img/download-prosppr-3.png" />
                    </div>
                  </div>
                  <div className="frame-149">
                    <div className="frame-150">
                      <div className="frame-151">
                        <div className="headline-10">Simplest Crypto Investment App</div>
                        <p className="text-wrapper-76">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin purus massa, scelerisque id
                          ipsum nec, blandit hendrerit tortor. Sed sed vestibulum arcu, Lorem ipsum dolor sit amet,
                          consectetur adipiscing
                        </p>
                        <img className="get-started" alt="Get started" src="/img/group-84.png" />
                      </div>
                      <img className="mock-up-leaf" alt="Mock up leaf" src="/img/group-84.png" />
                    </div>
                    <div className="frame-152">
                      <div className="start-with">
                        <div className="headline-11">Safety, you can Trust</div>
                        <p className="text-wrapper-77">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin purus massa, scelerisque id
                          ipsum nec, blandit hendrerit tortor. Sed sed vestibulum arcu, Lorem ipsum dolor sit amet,
                          consectetur adipiscing
                        </p>
                        <img className="get-started-2" alt="Get started" src="/img/group-84.png" />
                      </div>
                      <img className="mock-up-leaf-fold" alt="Mock up leaf fold" src="/img/group-84.png" />
                    </div>
                    <div className="frame-152">
                      <div className="start-with">
                        <div className="headline-12">Values user&#39;s risk tolerance</div>
                        <p className="text-wrapper-78">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin purus massa, scelerisque id
                          ipsum nec, blandit hendrerit tortor. Sed sed vestibulum arcu, Lorem ipsum dolor sit amet,
                          consectetur adipiscing
                        </p>
                        <img className="get-started-3" alt="Get started" src="/img/group-84.png" />
                      </div>
                      <img className="mock-up-leaf-2" alt="Mock up leaf" src="/img/group-84.png" />
                    </div>
                  </div>
                </div>
                <div className="features-blocks">
                  <div className="frame-153">
                    <div className="frame-154">
                      <div className="headline-13">How it works</div>
                      <p className="headling">Get Investing in just minutes</p>
                    </div>
                    <div className="frame-155">
                      <div className="block-one">
                        <img className="signup-icon" alt="Signup icon" src="/img/group-84.png" />
                        <div className="frame-156">
                          <div className="download-sign-up">Download &amp; Sign Up</div>
                          <p className="text-wrapper-79">
                            Create your free account with a quick verification process. It’s easy, we promise.
                          </p>
                        </div>
                      </div>
                      <div className="div-5">
                        <img className="link-your-account" alt="Link your account" src="/img/group-84.png" />
                        <div className="frame-156">
                          <div className="text-wrapper-80">Link your bank account</div>
                          <p className="text-wrapper-81">
                            Dip a toe or go all in. Connect your bank account securely — we’ll never retain your bank
                            info.
                          </p>
                        </div>
                      </div>
                      <div className="div-5">
                        <img className="get-investing-from" alt="Get investing from" src="/img/group-84.png" />
                        <div className="frame-156">
                          <p className="text-wrapper-82">Start Investing on the Go</p>
                          <p className="text-wrapper-79">
                            Pick your baskets, Add funds, set frequency Just hit the button. Your future self will thank
                            you.
                          </p>
                        </div>
                      </div>
                      <div className="div-5">
                        <img className="prosperity-logo" alt="Prosperity logo" src="/img/group-84.png" />
                        <div className="frame-156">
                          <p className="text-wrapper-83">Take the Road to Prosperity</p>
                          <p className="text-wrapper-81">
                            Get support, resources and all the best things from prosprr apart from the gains.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-157">
                  <img className="rectangle-7" alt="Rectangle" src="/img/group-84.png" />
                  <div className="overlap-18">
                    <div className="frame-158">
                      <div className="frame-154">
                        <p className="headline-14">Platform for your valuable Future</p>
                        <p className="headline-15">
                          Everything you need to get started with Crypto Investing for the long haul and make your
                          assets take you towards prosperity is here.
                        </p>
                      </div>
                      <div className="frame-159">
                        <div className="div-6">
                          <img className="icon-check" alt="Icon check" src="/img/group-84.png" />
                          <div className="frame-160">
                            <p className="start-with-2">
                              <span className="span">Start with </span>
                              <span className="text-wrapper-84">₹100</span>
                            </p>
                            <p className="text-wrapper-85">
                              Set the amount you want to invest per installment and forget. Our platform will do the
                              rest and automatically invest in cryptos for you
                            </p>
                          </div>
                        </div>
                        <div className="feature-block">
                          <img className="icon-check-2" alt="Icon check" src="/img/group-84.png" />
                          <div className="frame-161">
                            <div className="text-wrapper-86">Support at every step</div>
                          </div>
                        </div>
                        <div className="feature-block-2">
                          <img className="icon-check-3" alt="Icon check" src="/img/group-84.png" />
                          <div className="frame-161">
                            <div className="text-wrapper-87">24x7 Investing</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-162">
                      <div className="overlap-19">
                        <img className="vector-11" alt="Vector" src="/img/vector.png" />
                        <img className="cofee-mockup" alt="Cofee mockup" src="/img/vector.png" />
                      </div>
                      <div className="group-16">
                        <div className="overlap-group-9">
                          <img className="vector-12" alt="Vector" src="/img/vector.png" />
                          <img className="cofee-mockup-2" alt="Cofee mockup" src="/img/vector.png" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-163">
                  <div className="frame-164">
                    <div className="headline-16">Blog</div>
                    <div className="headline-17">Learn With Us</div>
                  </div>
                  <div className="frame-165">
                    <div className="how-compounding-5">
                      <div className="frame-166">
                        <img className="image-10" alt="Image" src="/img/group-84.png" />
                        <div className="frame-167">
                          <p className="text-wrapper-88">How Ruppee cost averaging works</p>
                          <div className="frame-168">
                            <p className="text-wrapper-89">Pellentesque habitant morbi tristique sen ...</p>
                            <img className="read-more-4" alt="Read more" src="/img/group-84.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="how-compounding-6">
                      <div className="frame-166">
                        <img className="image-11" alt="Image" src="/img/group-84.png" />
                        <div className="frame-167">
                          <p className="text-wrapper-88">How Ruppee cost averaging works</p>
                          <div className="frame-168">
                            <p className="text-wrapper-89">Pellentesque habitant morbi tristique sen ...</p>
                            <img className="read-more-5" alt="Read more" src="/img/group-84.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="how-compounding-7">
                      <div className="frame-166">
                        <img className="image-12" alt="Image" src="/img/group-84.png" />
                        <div className="frame-167">
                          <p className="text-wrapper-88">How Compounding helps to gain returns in SIP</p>
                          <div className="frame-168">
                            <p className="text-wrapper-89">Pellentesque habitant morbi tristique sen ...</p>
                            <img className="read-more-6" alt="Read more" src="/img/group-84.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-169">
                  <div className="frame-170">
                    <div className="frame-170">
                      <div className="frame-159">
                        <div className="frame-171">
                          <img className="prosppr-logo-2" alt="Prosppr logo" src="/img/group-84.png" />
                        </div>
                        <div className="frame-172">
                          <div className="text-wrapper-90">Get the app now!</div>
                          <div className="div-6">
                            <img className="image-13" alt="Image" src="/img/vector.png" />
                            <img className="image-14" alt="Image" src="/img/vector.png" />
                          </div>
                        </div>
                      </div>
                      <div className="frame-173">
                        <div className="frame-170">
                          <div className="text-wrapper-91">̱H̱ome</div>
                          <p className="text-wrapper-92">Why to invest on Crypto</p>
                          <div className="text-wrapper-92">Monthly Subscriptions</div>
                        </div>
                        <div className="frame-170">
                          <div className="text-wrapper-91">Market</div>
                          <div className="text-wrapper-92">Why Crypto SIP</div>
                          <div className="text-wrapper-92">About us</div>
                        </div>
                      </div>
                    </div>
                    <img className="divider-4" alt="Divider" src="/img/vector.png" />
                    <div className="frame-174">
                      <div className="text-wrapper-93">Follow us</div>
                      <img className="social-buttons-2" alt="Social buttons" src="/img/vector.png" />
                    </div>
                    <img className="divider-5" alt="Divider" src="/img/vector.png" />
                  </div>
                  <div className="frame-175">
                    <div className="text-wrapper-94">Copyright 2022 99xTechnologies LTD</div>
                    <div className="frame-176">
                      <div className="text-wrapper-95">Privacy Policy</div>
                      <div className="text-wrapper-95">Terms of service</div>
                    </div>
                  </div>
                </div>
                <div className="frame-177">
                  <div className="frame-178">
                    <p className="text-wrapper-96">Start your Crypto Portfolio with us</p>
                    <p className="text-wrapper-97">
                      Apply for early access to Prosprr, and be one of the first to join the movement.
                    </p>
                  </div>
                  <div className="frame-179">
                    <img className="leaf-3" alt="Leaf" src="/img/vector.png" />
                    <img className="element-3" alt="Element" src="/img/vector.png" />
                  </div>
                  <div className="frame-180">
                    <div className="frame-181">
                      <img className="android-store-2" alt="Android store" src="/img/group-84.png" />
                      <img className="apple-store-2" alt="Apple store" src="/img/group-84.png" />
                    </div>
                    <div className="frame-182">
                      <img className="image-15" alt="Image" src="/img/vector.png" />
                      <p className="text-wrapper-98">Scan the QR and download now</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="why-crypto-SIP-3">
                <img className="hero-section-BG-2" alt="Hero section BG" src="/img/group-84.png" />
                <div className="overlap-20">
                  <div className="component-2">
                    <div className="overlap-group-10">
                      <div className="ellipse-21" />
                      <div className="frame-183">
                        <div className="frame-95">
                          <div className="frame-154">
                            <div className="text-wrapper-99">Why Crypto SIP</div>
                            <div className="most-preferred-2">
                              Most Preferred <br />
                              Investment Avenue
                            </div>
                          </div>
                        </div>
                        <p className="text-wrapper-100">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin purus massa, scelerisque
                        </p>
                        <img className="get-started-4" alt="Get started" src="/img/group-84.png" />
                      </div>
                    </div>
                  </div>
                  <div className="frame-184">
                    <div className="overlap-21">
                      <div className="ellipse-22" />
                      <div className="frame-185">
                        <div className="headline-18">Jan 2021</div>
                        <div className="headline-19">₹3600</div>
                      </div>
                      <div className="frame-186">
                        <div className="headline-20">Feb 2021</div>
                        <div className="headline-21">₹3500</div>
                      </div>
                      <div className="group-17">
                        <div className="overlap-group-11">
                          <div className="ellipse-23" />
                          <div className="ellipse-24" />
                        </div>
                        <img className="vector-13" alt="Vector" src="/img/vector.png" />
                        <div className="ellipse-25" />
                      </div>
                      <div className="group-18">
                        <div className="overlap-group-11">
                          <div className="ellipse-23" />
                          <div className="ellipse-24" />
                        </div>
                        <img className="vector-14" alt="Vector" src="/img/vector.png" />
                        <div className="ellipse-25" />
                      </div>
                      <div className="group-19">
                        <div className="overlap-group-11">
                          <div className="ellipse-23" />
                          <div className="ellipse-24" />
                        </div>
                        <img className="vector-15" alt="Vector" src="/img/vector.png" />
                        <div className="ellipse-25" />
                      </div>
                      <div className="frame-187">
                        <div className="headline-20">Mar 2021</div>
                        <div className="headline-21">₹4000</div>
                      </div>
                    </div>
                    <div className="overlap-22">
                      <div className="overlap-23">
                        <div className="group-20">
                          <div className="overlap-24">
                            <div className="ellipse-26" />
                            <div className="ellipse-27" />
                          </div>
                          <img className="vector-16" alt="Vector" src="/img/vector.png" />
                          <div className="ellipse-28" />
                        </div>
                        <div className="frame-188">
                          <div className="headline-20">May 2021</div>
                          <div className="headline-21">₹3700</div>
                        </div>
                      </div>
                      <div className="overlap-25">
                        <div className="group-21">
                          <div className="overlap-24">
                            <div className="ellipse-26" />
                            <div className="ellipse-27" />
                          </div>
                          <img className="vector-17" alt="Vector" src="/img/vector.png" />
                          <div className="ellipse-28" />
                        </div>
                        <div className="frame-188">
                          <div className="headline-20">June 2021</div>
                          <div className="headline-21">₹3800</div>
                        </div>
                      </div>
                    </div>
                    <div className="overlap-26">
                      <div className="group-22">
                        <div className="overlap-27">
                          <div className="ellipse-23" />
                          <div className="ellipse-24" />
                        </div>
                        <img className="vector-18" alt="Vector" src="/img/vector.png" />
                        <div className="ellipse-25" />
                      </div>
                      <div className="frame-189">
                        <div className="headline-20">Apr 2021</div>
                        <div className="headline-21">₹4200</div>
                      </div>
                    </div>
                    <img className="vector-19" alt="Vector" src="/img/vector.png" />
                  </div>
                </div>
                <div className="frame-190">
                  <div className="frame-164">
                    <div className="headline-16">Blog</div>
                    <div className="headline-17">Learn With Us</div>
                  </div>
                  <div className="frame-165">
                    <div className="how-compounding-5">
                      <div className="frame-166">
                        <img className="image-16" alt="Image" src="/img/image-5.png" />
                        <div className="frame-167">
                          <p className="text-wrapper-88">How Ruppee cost averaging works</p>
                          <div className="frame-168">
                            <p className="text-wrapper-89">Pellentesque habitant morbi tristique sen ...</p>
                            <img className="read-more-7" alt="Read more" src="/img/group-84.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="how-compounding-6">
                      <div className="frame-166">
                        <img className="image-17" alt="Image" src="/img/image-4.png" />
                        <div className="frame-167">
                          <p className="text-wrapper-88">How Ruppee cost averaging works</p>
                          <div className="frame-168">
                            <p className="text-wrapper-89">Pellentesque habitant morbi tristique sen ...</p>
                            <img className="read-more-8" alt="Read more" src="/img/read-more-4.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="how-compounding-7">
                      <div className="frame-166">
                        <img className="image-18" alt="Image" src="/img/image-3.png" />
                        <div className="frame-167">
                          <p className="text-wrapper-88">How Compounding helps to gain returns in SIP</p>
                          <div className="frame-168">
                            <p className="text-wrapper-89">Pellentesque habitant morbi tristique sen ...</p>
                            <img className="read-more-9" alt="Read more" src="/img/read-more-3.png" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="frame-191">
                  <p className="text-wrapper-96">Start your Crypto Portfolio with us</p>
                  <p className="text-wrapper-97">
                    Apply for early access to Prosprr, and be one of the first to join the movement.
                  </p>
                </div>
                <div className="frame-192">
                  <img className="leaf-4" alt="Leaf" src="/img/vector.png" />
                  <img className="element-4" alt="Element" src="/img/2-2-1.png" />
                </div>
                <div className="frame-193">
                  <div className="frame-170">
                    <div className="frame-170">
                      <div className="frame-159">
                        <div className="frame-171">
                          <img className="prosppr-logo-3" alt="Prosppr logo" src="/img/group-84.png" />
                        </div>
                        <div className="frame-172">
                          <div className="text-wrapper-90">Get the app now!</div>
                          <div className="div-6">
                            <img className="image-19" alt="Image" src="/img/vector.png" />
                            <img className="image-20" alt="Image" src="/img/vector.png" />
                          </div>
                        </div>
                      </div>
                      <div className="frame-173">
                        <div className="frame-170">
                          <div className="text-wrapper-91">̱H̱ome</div>
                          <p className="text-wrapper-92">Why to invest on Crypto</p>
                          <div className="text-wrapper-92">Monthly Subscriptions</div>
                        </div>
                        <div className="frame-170">
                          <div className="text-wrapper-91">Market</div>
                          <div className="text-wrapper-92">Why Crypto SIP</div>
                          <div className="text-wrapper-92">About us</div>
                        </div>
                      </div>
                    </div>
                    <img className="divider-6" alt="Divider" src="/img/vector.png" />
                    <div className="frame-174">
                      <div className="text-wrapper-93">Follow us</div>
                      <img className="social-buttons-3" alt="Social buttons" src="/img/vector.png" />
                    </div>
                    <img className="divider-7" alt="Divider" src="/img/vector.png" />
                  </div>
                  <div className="frame-175">
                    <div className="text-wrapper-94">Copyright 2022 99xTechnologies LTD</div>
                    <div className="frame-176">
                      <div className="text-wrapper-95">Privacy Policy</div>
                      <div className="text-wrapper-95">Terms of service</div>
                    </div>
                  </div>
                </div>
                <div className="thumbs-up-2">
                  <img className="image-21" alt="Image" src="/img/image-36.png" />
                </div>
                <div className="frame-194">
                  <div className="frame-195">
                    <div className="frame-150">
                      <div className="frame-196">
                        <p className="headline-10">Enjoy the Convenience of Investing</p>
                        <p className="text-wrapper-100">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin purus massa, scelerisque id
                          ipsum nec, blandit hendrerit tortor. Sed sed vestibulum arcu, Lorem ipsum dolor sit amet,
                          consectetur adipiscing
                        </p>
                        <img className="get-started-5" alt="Get started" src="/img/group-84.png" />
                      </div>
                      <div className="group-23">
                        <div className="overlap-28">
                          <div className="ellipse-29" />
                          <div className="getpaidstock-wrapper">
                            <img className="getpaidstock-2" alt="Getpaidstock" src="/img/vector.png" />
                          </div>
                          <div className="cryptobasket-2">
                            <div className="overlap-group-12">
                              <div className="rectangle-8" />
                              <div className="text-wrapper-101">Basket</div>
                              <div className="rectangle-9" />
                              <div className="text-wrapper-102">Coin</div>
                            </div>
                            <div className="text-wrapper-103">Enter Frequency</div>
                            <div className="text-wrapper-104">Duration</div>
                            <div className="frame-197">
                              <div className="text-wrapper-105">6 months</div>
                            </div>
                            <div className="frame-198">
                              <div className="text-wrapper-105">₹ 3,500</div>
                            </div>
                          </div>
                        </div>
                        <img className="leaf-5" alt="Leaf" src="/img/vector.png" />
                        <img className="istock-2" alt="Istock" src="/img/vector.png" />
                      </div>
                    </div>
                    <div className="frame-150">
                      <div className="frame-196">
                        <p className="headline-10">Rupee Cost Averaging across the Time</p>
                        <p className="text-wrapper-100">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin purus massa, scelerisque id
                          ipsum nec, blandit hendrerit tortor. Sed sed vestibulum arcu, Lorem ipsum dolor sit amet,
                          consectetur adipiscing
                        </p>
                        <img className="get-started-6" alt="Get started" src="/img/group-84.png" />
                      </div>
                      <div className="group-24">
                        <div className="image-22" />
                        <div className="ETH-2">
                          <img className="group-25" alt="Group" src="/img/vector.png" />
                          <div className="frame-199">
                            <div className="frame-200">
                              <div className="frame-201">
                                <img className="ethereum-logo-2" alt="Ethereum logo" src="/img/vector.png" />
                              </div>
                              <div className="rise-3">
                                <img className="group-26" alt="Group" src="/img/vector.png" />
                                <div className="text-wrapper-106">87%</div>
                              </div>
                            </div>
                            <div className="frame-43">
                              <div className="frame-43">
                                <div className="text-wrapper-107">Etherium ETH</div>
                                <div className="text-wrapper-108">₹ 3,500</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-150">
                      <div className="frame-196">
                        <div className="headline-10">Well-diversified portfolios</div>
                        <p className="text-wrapper-100">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin purus massa, scelerisque id
                          ipsum nec, blandit hendrerit tortor. Sed sed vestibulum arcu, Lorem ipsum dolor sit amet,
                          consectetur adipiscing
                        </p>
                        <img className="get-started-7" alt="Get started" src="/img/get-started-1.png" />
                      </div>
                      <div className="group-27">
                        <div className="overlap-29">
                          <img className="check-2" alt="Check" src="/img/check-1.svg" />
                          <div className="frame-202">
                            <div className="frame-203">
                              <div className="frame-204">
                                <div className="group-28">
                                  <div className="overlap-30">
                                    <div className="group-29">
                                      <img className="vector-20" alt="Vector" src="/img/vector-4.svg" />
                                    </div>
                                    <div className="group-30">
                                      <img className="vector-21" alt="Vector" src="/img/vector-3.svg" />
                                    </div>
                                    <div className="group-31">
                                      <div className="group-32">
                                        <div className="overlap-group-13">
                                          <div className="rectangle-10" />
                                          <img className="PNG-vector" alt="Png" src="/img/png-vector69-3.png" />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="group-33">
                                      <img className="vector-20" alt="Vector" src="/img/vector-2.svg" />
                                    </div>
                                  </div>
                                </div>
                                <div className="frame-134">
                                  <div className="CTA-2">
                                    <div className="text-wrapper-109">Invest Now</div>
                                  </div>
                                  <img className="frame-205" alt="Frame" src="/img/frame-173.svg" />
                                </div>
                              </div>
                              <p className="text-wrapper-110">BNB + SOL + LUNA + DOT</p>
                            </div>
                            <div className="frame-206">
                              <div className="frame-207">
                                <div className="text-wrapper-111">Min Inv.</div>
                                <div className="text-wrapper-112">₹70.00</div>
                              </div>
                              <div className="frame-207">
                                <div className="text-wrapper-111">1Y Return</div>
                                <div className="rise-4">
                                  <img className="group-34" alt="Group" src="/img/group.png" />
                                  <div className="text-wrapper-113">87%</div>
                                </div>
                              </div>
                              <div className="frame-207">
                                <div className="text-wrapper-111">Total AUM</div>
                                <div className="text-wrapper-112">₹1.54 Cr</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-208">
                    <div className="overlap-31">
                      <div className="frame-209">
                        <div className="overlap-group-14">
                          <img className="rectangle-11" alt="Rectangle" src="/img/rectangle-94.png" />
                          <div className="frame-210">
                            <div className="frame-151">
                              <div className="frame-154">
                                <div className="headline-22">Investments in Autopilot</div>
                                <p className="headline-15">
                                  Everything you need to get started with Crypto Investing for the long haul and make
                                  your assets take you towards prosperity is here.
                                </p>
                              </div>
                              <img className="get-started-7" alt="Get started" src="/img/get-started.png" />
                            </div>
                          </div>
                        </div>
                      </div>
                      <img className="investment-on-2" alt="Investment on" src="/img/investment-on-autopilot-bg.png" />
                    </div>
                  </div>
                </div>
                <div className="frame-211">
                  <div className="frame-148">
                    <NavigationMenu
                      className="navigation-menu-24px"
                      color="/img/vector.png"
                      colorClassName="navigation-menu-px-instance"
                    />
                    <img className="vector-22" alt="Vector" src="/img/vector.png" />
                  </div>
                  <img className="download-prosppr-2" alt="Download prosppr" src="/img/group-84.png" />
                </div>
                <div className="frame-212">
                  <div className="frame-181">
                    <img className="android-store-3" alt="Android store" src="/img/android-store.png" />
                    <img className="apple-store-3" alt="Apple store" src="/img/apple-store.png" />
                  </div>
                  <div className="frame-182">
                    <img className="image-23" alt="Image" src="/img/vector.png" />
                    <p className="text-wrapper-98">Scan the QR and download now</p>
                  </div>
                </div>
              </div>
              <div className="learning-blog-2">
                <div className="overlap-32">
                  <div className="frame-213">
                    <div className="frame-170">
                      <div className="frame-170">
                        <div className="frame-159">
                          <div className="frame-171">
                            <img className="prosppr-logo-4" alt="Prosppr logo" src="/img/group-84.png" />
                          </div>
                          <div className="frame-172">
                            <div className="text-wrapper-90">Get the app now!</div>
                            <div className="div-6">
                              <img className="image-24" alt="Image" src="/img/vector.png" />
                              <img className="image-25" alt="Image" src="/img/vector.png" />
                            </div>
                          </div>
                        </div>
                        <div className="frame-173">
                          <div className="frame-170">
                            <div className="text-wrapper-91">̱H̱ome</div>
                            <p className="text-wrapper-92">Why to invest on Crypto</p>
                            <div className="text-wrapper-92">Monthly Subscriptions</div>
                          </div>
                          <div className="frame-170">
                            <div className="text-wrapper-91">Market</div>
                            <div className="text-wrapper-92">Why Crypto SIP</div>
                            <div className="text-wrapper-92">About us</div>
                          </div>
                        </div>
                      </div>
                      <img className="divider-8" alt="Divider" src="/img/vector.png" />
                      <div className="frame-174">
                        <div className="text-wrapper-93">Follow us</div>
                        <img className="social-buttons-4" alt="Social buttons" src="/img/vector.png" />
                      </div>
                      <img className="divider-9" alt="Divider" src="/img/vector.png" />
                    </div>
                    <div className="frame-175">
                      <div className="text-wrapper-94">Copyright 2022 99xTechnologies LTD</div>
                      <div className="frame-176">
                        <div className="text-wrapper-95">Privacy Policy</div>
                        <div className="text-wrapper-95">Terms of service</div>
                      </div>
                    </div>
                  </div>
                  <div className="frame-214">
                    <div className="frame-215">
                      <div className="text-wrapper-114">Essentials</div>
                      <img className="rectangle-12" alt="Rectangle" src="/img/group-84.png" />
                    </div>
                    <div className="frame-159">
                      <div className="frame-216">
                        <div className="frame-217">
                          <div className="overlap-group-15">
                            <img className="image-26" alt="Image" src="/img/group-84.png" />
                            <img className="image-27" alt="Image" src="/img/vector.png" />
                            <img className="image-28" alt="Image" src="/img/vector.png" />
                            <img className="image-29" alt="Image" src="/img/vector.png" />
                          </div>
                        </div>
                        <div className="frame-218">
                          <p className="text-wrapper-115">
                            Amet laoreet mi ultricies. Sed nisl mauris, congue Integer porttitor magna
                          </p>
                          <div className="frame-219">
                            <div className="text-wrapper-116">AUTHORS NAME</div>
                            <div className="ellipse-30" />
                            <div className="text-wrapper-117">November 18, 2022</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-220">
                        <div className="frame-76">
                          <div className="frame-221">
                            <div className="frame-222">
                              <div className="frame-223">
                                <img className="image-30" alt="Image" src="/img/vector.png" />
                              </div>
                              <div className="frame-27">
                                <p className="text-wrapper-118">Quisque ut neque ac risus suscipit facilisis.</p>
                                <div className="frame-224">
                                  <div className="text-wrapper-119">AUTHORS NAME</div>
                                  <div className="ellipse-30" />
                                  <div className="text-wrapper-120">Nov 18, 2022</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-221">
                            <div className="frame-222">
                              <div className="frame-223">
                                <img className="image-31" alt="Image" src="/img/vector.png" />
                              </div>
                              <div className="frame-27">
                                <p className="text-wrapper-118">Quisque ut neque ac risus suscipit facilisis.</p>
                                <div className="frame-224">
                                  <div className="text-wrapper-119">AUTHORS NAME</div>
                                  <div className="ellipse-30" />
                                  <div className="text-wrapper-120">Nov 18, 2022</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <img className="more-blogs-2" alt="More blogs" src="/img/group-84.png" />
                      </div>
                    </div>
                  </div>
                  <div className="frame-225">
                    <div className="frame-215">
                      <div className="text-wrapper-114">Blockchain</div>
                      <img className="rectangle-13" alt="Rectangle" src="/img/rectangle-85-2.png" />
                    </div>
                    <div className="frame-159">
                      <div className="frame-216">
                        <div className="frame-217">
                          <div className="overlap-group-16">
                            <img className="image-32" alt="Image" src="/img/image-40-9.png" />
                            <img className="image-32" alt="Image" src="/img/image-41-1.png" />
                            <img className="image-32" alt="Image" src="/img/image-42.png" />
                          </div>
                        </div>
                        <div className="frame-218">
                          <p className="text-wrapper-115">
                            Amet laoreet mi ultricies. Sed nisl mauris, congue Integer porttitor magna
                          </p>
                          <div className="frame-219">
                            <div className="text-wrapper-116">AUTHORS NAME</div>
                            <div className="ellipse-30" />
                            <div className="text-wrapper-117">November 18, 2022</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-220">
                        <div className="frame-76">
                          <div className="frame-221">
                            <div className="frame-222">
                              <div className="frame-223">
                                <img className="image-33" alt="Image" src="/img/image-40-8.png" />
                              </div>
                              <div className="frame-27">
                                <p className="text-wrapper-118">Quisque ut neque ac risus suscipit facilisis.</p>
                                <div className="frame-224">
                                  <div className="text-wrapper-119">AUTHORS NAME</div>
                                  <div className="ellipse-30" />
                                  <div className="text-wrapper-120">Nov 18, 2022</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-221">
                            <div className="frame-222">
                              <div className="frame-223">
                                <img className="image-34" alt="Image" src="/img/vector.png" />
                              </div>
                              <div className="frame-27">
                                <p className="text-wrapper-118">Quisque ut neque ac risus suscipit facilisis.</p>
                                <div className="frame-224">
                                  <div className="text-wrapper-119">AUTHORS NAME</div>
                                  <div className="ellipse-30" />
                                  <div className="text-wrapper-120">Nov 18, 2022</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <img className="more-blogs-3" alt="More blogs" src="/img/group-84.png" />
                      </div>
                    </div>
                  </div>
                  <div className="frame-226">
                    <div className="frame-215">
                      <div className="text-wrapper-114">Crypto Baskets</div>
                      <img className="rectangle-13" alt="Rectangle" src="/img/rectangle-85-1.png" />
                    </div>
                    <div className="frame-159">
                      <div className="frame-216">
                        <div className="frame-217">
                          <div className="overlap-group-16">
                            <img className="image-32" alt="Image" src="/img/image-40-6.png" />
                            <img className="image-32" alt="Image" src="/img/image-41.png" />
                          </div>
                        </div>
                        <div className="frame-218">
                          <p className="text-wrapper-115">
                            Amet laoreet mi ultricies. Sed nisl mauris, congue Integer porttitor magna
                          </p>
                          <div className="frame-219">
                            <div className="text-wrapper-116">AUTHORS NAME</div>
                            <div className="ellipse-30" />
                            <div className="text-wrapper-117">November 18, 2022</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-220">
                        <div className="frame-76">
                          <div className="frame-221">
                            <div className="frame-222">
                              <div className="frame-223">
                                <img className="image-35" alt="Image" src="/img/image-40-5.png" />
                              </div>
                              <div className="frame-27">
                                <p className="text-wrapper-118">Quisque ut neque ac risus suscipit facilisis.</p>
                                <div className="frame-224">
                                  <div className="text-wrapper-119">AUTHORS NAME</div>
                                  <div className="ellipse-30" />
                                  <div className="text-wrapper-120">Nov 18, 2022</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-221">
                            <div className="frame-222">
                              <div className="frame-223">
                                <img className="image-35" alt="Image" src="/img/image-40-4.png" />
                              </div>
                              <div className="frame-27">
                                <p className="text-wrapper-118">Quisque ut neque ac risus suscipit facilisis.</p>
                                <div className="frame-224">
                                  <div className="text-wrapper-119">AUTHORS NAME</div>
                                  <div className="ellipse-30" />
                                  <div className="text-wrapper-120">Nov 18, 2022</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <img className="more-blogs-4" alt="More blogs" src="/img/more-blogs-1.png" />
                      </div>
                    </div>
                  </div>
                  <div className="frame-227">
                    <div className="frame-215">
                      <div className="text-wrapper-114">Cryptocurrency</div>
                      <img className="rectangle-14" alt="Rectangle" src="/img/image-40-2.png" />
                    </div>
                    <div className="frame-159">
                      <div className="frame-216">
                        <div className="frame-217">
                          <img className="image-36" alt="Image" src="/img/image-40-2.png" />
                        </div>
                        <div className="frame-218">
                          <p className="text-wrapper-115">
                            Amet laoreet mi ultricies. Sed nisl mauris, congue Integer porttitor magna
                          </p>
                          <div className="frame-219">
                            <div className="text-wrapper-116">AUTHORS NAME</div>
                            <div className="ellipse-30" />
                            <div className="text-wrapper-117">November 18, 2022</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-220">
                        <div className="frame-76">
                          <div className="frame-221">
                            <div className="frame-222">
                              <div className="frame-223">
                                <img className="image-35" alt="Image" src="/img/image-40-1.png" />
                              </div>
                              <div className="frame-27">
                                <p className="text-wrapper-118">Quisque ut neque ac risus suscipit facilisis.</p>
                                <div className="frame-224">
                                  <div className="text-wrapper-119">AUTHORS NAME</div>
                                  <div className="ellipse-30" />
                                  <div className="text-wrapper-120">Nov 18, 2022</div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="frame-221">
                            <div className="frame-222">
                              <div className="frame-223">
                                <img className="image-35" alt="Image" src="/img/image-40.png" />
                              </div>
                              <div className="frame-27">
                                <p className="text-wrapper-118">Quisque ut neque ac risus suscipit facilisis.</p>
                                <div className="frame-224">
                                  <div className="text-wrapper-119">AUTHORS NAME</div>
                                  <div className="ellipse-30" />
                                  <div className="text-wrapper-120">Nov 18, 2022</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <img className="more-blogs-4" alt="More blogs" src="/img/more-blogs.png" />
                      </div>
                    </div>
                  </div>
                </div>
                <img className="hero-section-BG-3" alt="Hero section BG" src="/img/group-84.png" />
                <div className="overlap-33">
                  <div className="ellipse-31" />
                  <div className="component-3">
                    <div className="overlap-34">
                      <div className="ellipse-32" />
                      <div className="frame-228">
                        <div className="frame-154">
                          <p className="text-wrapper-121">Learn to Invest With Us</p>
                        </div>
                        <p className="text-wrapper-100">
                          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin purus massa, scelerisque
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="frame-229">
                    <div className="frame-148">
                      <NavigationMenu
                        className="navigation-menu-24px"
                        color="/img/vector.png"
                        colorClassName="navigation-menu-24px-instance"
                      />
                      <img className="vector-23" alt="Vector" src="/img/vector.png" />
                    </div>
                    <img className="download-prosppr-3" alt="Download prosppr" src="/img/group-84.png" />
                  </div>
                  <div className="frame-230">
                    <div className="frame-195">
                      <div className="how-compounding-8">
                        <div className="frame-231">
                          <img className="image-37" alt="Image" src="/img/group-84.png" />
                          <div className="frame-232">
                            <div className="text-wrapper-122">LOROM IPSUM</div>
                            <div className="frame-233">
                              <div className="frame-234">
                                <div className="frame-234">
                                  <p className="text-wrapper-123">How Compounding helps to gain returns in SIP</p>
                                  <p className="text-wrapper-124">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin purus massa,
                                    scelerisque id ipsum nec, blandit hendrerit tortor.
                                  </p>
                                </div>
                                <div className="text-wrapper-125">Read More</div>
                              </div>
                              <div className="frame-224">
                                <div className="text-wrapper-119">AUTHORS NAME</div>
                                <div className="ellipse-33" />
                                <div className="text-wrapper-120">November 18, 2022</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="popular-2">
                        <div className="frame-235">
                          <div className="text-wrapper-126">Popular</div>
                        </div>
                        <div className="frame-172">
                          <div className="frame-236">
                            <div className="text-wrapper-127">LOROM IPSUM</div>
                            <p className="text-wrapper-128">Lorem ipsum dolor sit amet, consectetur</p>
                          </div>
                          <div className="frame-236">
                            <div className="text-wrapper-129">LOROM IPSUM</div>
                            <p className="text-wrapper-128">Lorem ipsum dolor sit amet, consectetur</p>
                          </div>
                          <div className="frame-236">
                            <div className="text-wrapper-130">LOROM IPSUM</div>
                            <p className="text-wrapper-128">Lorem ipsum dolor sit amet, consectetur</p>
                          </div>
                          <div className="frame-236">
                            <div className="text-wrapper-129">LOROM IPSUM</div>
                            <p className="text-wrapper-128">Lorem ipsum dolor sit amet, consectetur</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="frame-237">
                      <div className="frame-238">
                        <div className="frame-239">
                          <div className="text-wrapper-131">Latest blogs</div>
                        </div>
                        <div className="div-6">
                          <div className="frame-240">
                            <div className="text-wrapper-132">All</div>
                          </div>
                          <div className="frame-241">
                            <div className="text-wrapper-133">Cryptocurrency</div>
                          </div>
                          <div className="frame-241">
                            <div className="text-wrapper-133">Investment</div>
                          </div>
                          <div className="frame-242">
                            <div className="text-wrapper-133">Trading</div>
                          </div>
                          <div className="frame-243">
                            <div className="text-wrapper-133">Blockchain</div>
                          </div>
                          <div className="frame-244">
                            <div className="text-wrapper-133">Security</div>
                          </div>
                        </div>
                      </div>
                      <div className="frame-245">
                        <div className="frame-246">
                          <div className="frame-247">
                            <img className="image-38" alt="Image" src="/img/image-38-2.png" />
                          </div>
                          <div className="frame-248">
                            <div className="frame-234">
                              <div className="frame-234">
                                <div className="text-wrapper-134">LOROM IPSUM</div>
                                <div className="frame-234">
                                  <p className="text-wrapper-115">Amet laoreet mi ultricies. Sed nisl mauris, congue</p>
                                  <div className="frame-234">
                                    <p className="text-wrapper-135">
                                      Suspendisse purus lorem, viverra lacinia magna sed, luctus tincidunt velit. Nam
                                      quam tortor.
                                    </p>
                                    <img className="read-more-10" alt="Read more" src="/img/read-more-2.png" />
                                  </div>
                                </div>
                              </div>
                              <div className="frame-249">
                                <div className="text-wrapper-116">AUTHORS NAME</div>
                                <div className="ellipse-30" />
                                <div className="text-wrapper-117">November 18, 2022</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-246">
                          <div className="frame-247">
                            <div className="overlap-group-17">
                              <img className="image-39" alt="Image" src="/img/image-38-1.png" />
                              <img className="image-40" alt="Image" src="/img/image-39-1.png" />
                            </div>
                          </div>
                          <div className="frame-248">
                            <div className="frame-234">
                              <div className="frame-234">
                                <div className="text-wrapper-134">LOROM IPSUM</div>
                                <div className="frame-234">
                                  <p className="text-wrapper-115">Amet laoreet mi ultricies. Sed nisl mauris, congue</p>
                                  <div className="frame-234">
                                    <p className="text-wrapper-135">
                                      Suspendisse purus lorem, viverra lacinia magna sed, luctus tincidunt velit. Nam
                                      quam tortor.
                                    </p>
                                    <img className="read-more-10" alt="Read more" src="/img/read-more-1.png" />
                                  </div>
                                </div>
                              </div>
                              <div className="frame-249">
                                <div className="text-wrapper-116">AUTHORS NAME</div>
                                <div className="ellipse-30" />
                                <div className="text-wrapper-117">November 18, 2022</div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="frame-246">
                          <div className="frame-247">
                            <div className="overlap-group-17">
                              <img className="image-39" alt="Image" src="/img/image-38.png" />
                              <img className="image-40" alt="Image" src="/img/image-39.png" />
                              <img className="image-41" alt="Image" src="/img/image-40-3.png" />
                            </div>
                          </div>
                          <div className="frame-248">
                            <div className="frame-234">
                              <div className="frame-234">
                                <div className="text-wrapper-134">LOROM IPSUM</div>
                                <div className="frame-234">
                                  <p className="text-wrapper-115">Amet laoreet mi ultricies. Sed nisl mauris, congue</p>
                                  <div className="frame-234">
                                    <p className="text-wrapper-135">
                                      Suspendisse purus lorem, viverra lacinia magna sed, luctus tincidunt velit. Nam
                                      quam tortor.
                                    </p>
                                    <img className="read-more-10" alt="Read more" src="/img/read-more.png" />
                                  </div>
                                </div>
                              </div>
                              <div className="frame-249">
                                <div className="text-wrapper-116">AUTHORS NAME</div>
                                <div className="ellipse-30" />
                                <div className="text-wrapper-117">November 18, 2022</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="menu">
                <div className="frame-250">
                  <div className="frame-251">
                    <div className="text-wrapper-136">About us</div>
                  </div>
                  <div className="frame-251">
                    <div className="text-wrapper-136">Market</div>
                  </div>
                  <div className="frame-251">
                    <p className="text-wrapper-136">Why to invest on Crypto</p>
                  </div>
                  <div className="frame-251">
                    <div className="text-wrapper-136">Why Crypto SIP</div>
                  </div>
                </div>
                <div className="frame-252">
                  <div className="frame-148">
                    <NavigationMenu
                      className="navigation-menu-24px"
                      color="/img/color-1.svg"
                      colorClassName="navigation-menu-instance"
                    />
                    <img className="frame-253" alt="Frame" src="/img/frame-40384.svg" />
                  </div>
                  <img className="download-prosppr-4" alt="Download prosppr" src="/img/download-prosppr.png" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
